import { useDisclosure } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import type React from 'react'
import { useSelector } from 'react-redux'
import AddWalletModal from '../../../../components/AddWalletModal'
import PageWrapper from '../../../../components/Pagewrapper'
import Tooltip from '../../../../components/Tooltip'
import AsyncAlert from '../../../../containers/AsyncAlert'
import {
  useDeleteWalletMutation,
  useGetWalletsQuery,
} from '../../../../redux/services/coreApi'
import type { RootState } from '../../../../redux/store'
import type { WalletDto } from '../../../../types/coreApi-types'
import useAsyncAlert from '../../../../utils/useAsyncAlert'
import { WalletItem } from './components/WalletItem'

const WalletsScreen: React.FC = () => {
  const addWalletModal = useDisclosure()

  const userInfo = useSelector((state: RootState) => state.session.user)

  const wallets = useGetWalletsQuery(null)
  const [
    deleteWallet,
    { isError: deleteWalletError, reset: resetDeleteWallet },
  ] = useDeleteWalletMutation()

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    WalletDto,
    WalletDto
  >()

  return (
    <PageWrapper title="Wallets" description="Browse your wallets" fixedWidth>
      <AddWalletModal
        isOpen={addWalletModal.isOpen}
        onClose={addWalletModal.onClose}
      />

      <div className="space-y-2">
        {wallets.data &&
          wallets.data.map((item, idx) => {
            return (
              <WalletItem
                key={idx}
                address={item.address}
                destinationTag={item.destinationTag}
                network={item.network}
                onPressDelete={async () => {
                  resetDeleteWallet()
                  await openAlertAndAwaitConfirmation(item, item)
                }}
              />
            )
          })}
      </div>

      <div className="flex justify-center mt-10">
        <Tooltip
          label={
            userInfo.kycDetails?.kycStatus !== 'Approved'
              ? 'You need to have completed your KYC process to add a wallet'
              : undefined
          }
        >
          <button
            disabled={userInfo.kycDetails?.kycStatus !== 'Approved'}
            onClick={addWalletModal.onOpen}
            className="button min-w-[130px] "
          >
            Add wallet
          </button>
        </Tooltip>
      </div>

      <AsyncAlert
        title={deleteWalletError ? 'Error' : 'Delete this wallet?'}
        bodyText={wallet =>
          deleteWalletError
            ? 'You cannot delete your wallet. This is probably due to existing transfers associated to this wallet. Please contact support if you must delete or change this wallet.'
            : `Are you sure you want to delete wallet with address: ${wallet?.address}? Keep in mind you are not allowed to delete wallets that have associated transactions.`
        }
        submitButtonText={deleteWalletError ? 'Close' : 'Delete wallet'}
        onClick={async payload => {
          if (deleteWalletError) return

          if (payload) {
            const promise = deleteWallet(payload.id).unwrap()
            promise.catch(captureException)
            return promise
          }
        }}
        {...alertProps}
      />
    </PageWrapper>
  )
}

export default WalletsScreen
