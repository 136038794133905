import { useAuth0 } from '@auth0/auth0-react'
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import type React from 'react'
import { useReducer } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { RiMenu2Line } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AnalyticsUpIcon from '../../../assets/icons/analytics-up-stroke-rounded (1)'
import ArrowDataTransferHorizontalIcon from '../../../assets/icons/arrow-data-transfer-horizontal-stroke-rounded (1)'
import BankIcon from '../../../assets/icons/bank-stroke-rounded'
import DashboardSpeed02Icon from '../../../assets/icons/dashboard-speed-02-stroke-rounded'
import Logout02Icon from '../../../assets/icons/logout-02-stroke-rounded (1)'
import Money01Icon from '../../../assets/icons/money-01-stroke-rounded'
import UserGroupIcon from '../../../assets/icons/user-group-stroke-rounded (2)'
import Wallet03Icon from '../../../assets/icons/wallet-03-stroke-rounded'
import ZapIcon from '../../../assets/icons/zap-stroke-rounded'
import penningSymbol from '../../../assets/symbol.png'
import UserIcon from '../../../assets/user-stroke-rounded'
import MenuItem from '../../../components/MenuItem'
import WelcomeModal from '../../../components/WelcomeModal'
import { VERSION } from '../../../constants'
import { AUTH0_LOGOUT_URL } from '../../../constants'
import type { RootState } from '../../../redux/store'
import NeedHelp from './NeedHelp'

type Props = {}

const AppMenu: React.FC<Props> = () => {
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const betaModal = useDisclosure()

  const { logout } = useAuth0()

  const sessionUser = useSelector((state: RootState) => state.session?.user)

  type Page = {
    title: string
    path: string
    icon: JSX.Element
    comingSoon?: boolean
    isNewFeature?: boolean
    links?: { title: string; path: string }[]
  }

  const individualPages: Page[] = [
    {
      title: 'Get started',
      path: '/app/get-started',
      icon: <ZapIcon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Investor',
      path: '/app/investor',
      icon: <AnalyticsUpIcon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Broker service',
      path: '/app/broker-service',
      icon: (
        <ArrowDataTransferHorizontalIcon className="w-5 h-5 text-inherit" />
      ),
    },
    {
      title: 'Wallets',
      path: '/app/wallets',
      icon: <Wallet03Icon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Bank Accounts',
      path: '/app/bank-accounts',
      icon: <BankIcon className="w-5 h-5 text-inherit" />,
    },

    {
      title: 'Account',
      path: '/app/account',
      icon: <UserIcon className="w-5 h-5 text-inherit" />,
    },
  ]

  const adminPages: Page[] = [
    {
      title: 'Clients',
      path: '/app/admin/clients',
      icon: <UserGroupIcon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Transfers',
      path: '/app/admin/transfers',
      icon: (
        <ArrowDataTransferHorizontalIcon className="w-5 h-5 text-inherit" />
      ),
    },
    {
      title: 'Currency Overview',
      path: '/app/admin/currencies',
      icon: <Money01Icon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'OTC Performance',
      path: '/app/admin/performance',
      icon: <DashboardSpeed02Icon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Fund performance',
      path: '/app/admin/fund',
      icon: <AnalyticsUpIcon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Account',
      path: '/app/account',
      icon: <UserIcon className="w-5 h-5 text-inherit" />,
    },
  ]

  const amlOfficerPages: Page[] = [
    {
      title: 'Clients',
      path: '/app/admin/clients',
      icon: <UserGroupIcon className="w-5 h-5 text-inherit" />,
    },
    {
      title: 'Transfers',
      path: '/app/admin/transfers',
      icon: (
        <ArrowDataTransferHorizontalIcon className="w-5 h-5 text-inherit" />
      ),
    },
    {
      title: 'Account',
      path: '/app/account',
      icon: <UserIcon className="w-5 h-5 text-inherit" />,
    },
  ]

  const utilsItems = [
    {
      title: 'Log out',
      icon: <Logout02Icon className="w-5 h-5 text-inherit" />,
      onClick: () => logout({ returnTo: AUTH0_LOGOUT_URL }),
    },
  ]

  const pagesToMap =
    sessionUser.role === 'Admin'
      ? sessionUser.isAmlOfficer
        ? amlOfficerPages
        : adminPages
      : individualPages

  window.addEventListener('storage', () => {
    forceUpdate()
  })

  const isDynamicSidebarActivated = () => {
    return localStorage.getItem('dynamic-sidebar') === 'on'
  }

  return (
    <div
      className={`${
        isDynamicSidebarActivated()
          ? 'md:w-[55px] w-full '
          : 'w-full md:w-[250px]'
      } h-[60px] md:h-full w-full transition-all shrink-0  group bg-brand-app-menu dark:bg-brand-dark-card flex md:flex-col p-2  border-r-[1px] border-solid border-transparent dark:border-r-brand-dark-card-border z-50`}
    >
      <WelcomeModal isOpen={betaModal.isOpen} onClose={betaModal.onClose} />

      <div className="hidden md:flex md:flex-col gap-1 justify-between h-full">
        <div>
          <div className="flex items-center gap-3 mb-10 p-1.5 overflow-hidden whitespace-nowrap">
            <div className="h-8 w-8 flex items-center justify-center shrink-0 dark:bg-brand-dark-background bg-brand-app-menu-hover rounded-lg">
              <img src={penningSymbol} alt="Penning logo" className="h-5" />
            </div>
            {isDynamicSidebarActivated() ? (
              <></>
            ) : (
              <div
                onClick={betaModal.onOpen}
                className="bg-brand-dark-orange py-1 px-2 rounded-full cursor-pointer"
              >
                <h1 className="text-white font-semi text-[10px]">
                  BETA (v{VERSION})
                </h1>
              </div>
            )}
          </div>

          {pagesToMap.map((item, i) => {
            const isActive = history.location.pathname.includes(item.path)
            return (
              <MenuItem
                key={i}
                icon={item.icon}
                title={item.title}
                onClick={() => {
                  if (item.links) {
                    history.push(item.links[0].path)
                  } else {
                    history.push(item.path)
                  }
                }}
                isNewFeature={item.isNewFeature}
                isActive={isActive}
                comingSoon={item.comingSoon}
                links={item.links}
              />
            )
          })}
        </div>
        <div>
          <NeedHelp
            company={
              history.location.pathname.includes('investor') ? 'pcm' : 'otc'
            }
          />

          {utilsItems.map((item, i) => {
            return (
              <MenuItem
                key={i}
                icon={item.icon}
                title={item.title}
                onClick={item.onClick}
                isActive={false}
              />
            )
          })}
        </div>
      </div>
      <div className="md:hidden text-white flex items-center px-1 ">
        <div className="p-1" onClick={onOpen}>
          <RiMenu2Line color="#fff" size={24} />
        </div>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
          <DrawerOverlay />
          <DrawerContent bg="#29383B">
            <div className="flex items-center justify-between px-5 pt-5">
              {/* <img className="w-[50px]" src={logo} alt="Penning logo" /> */}

              <div className="p-4 rounded-full" onClick={onClose}>
                <MdOutlineClose size={26} color="#fff" />
              </div>
            </div>

            <DrawerBody mt="20px">
              <div className="flex flex-col gap-2">
                {pagesToMap.map((item, i) => {
                  const isActive = history.location.pathname.includes(item.path)

                  return (
                    <MenuItem
                      key={i}
                      icon={item.icon}
                      isActive={isActive}
                      onClick={() => {
                        history.push(item.path)
                        onClose()
                      }}
                      title={item.title}
                      comingSoon={item.comingSoon}
                    />
                  )
                })}
                <div className="mt-10">
                  <NeedHelp
                    company={
                      history.location.pathname.includes('investor')
                        ? 'pcm'
                        : 'otc'
                    }
                  />
                  {utilsItems.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        icon={item.icon}
                        title={item.title}
                        onClick={item.onClick}
                        isActive={false}
                      />
                    )
                  })}
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
    </div>
  )
}

export default AppMenu
