import { Spinner, useDisclosure, useToast } from '@chakra-ui/react'
import {
  faCircleUp,
  faEllipsisH,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import DropdownCell from '../../../../../components/DropdownCell'
import DropdownMenu from '../../../../../components/DropdownMenu'
import InfoBox from '../../../../../components/InfoBox'
import KycStatus from '../../../../../components/KycStatus'
import Toast from '../../../../../components/Toast'
import {
  useCreateKycCaseMutation,
  useInviteMemberMutation,
  useLinkUltimateBeneficialOwnersMutation,
} from '../../../../../redux/services/coreApi'
import type { UserDto } from '../../../../../types/coreApi-types'
import { getInitials } from '../../../../../utils/getInitials'
import UpgradeToUboModal from './UpgradeToUboModal'

interface Props {
  member: UserDto
  disableActions?: boolean
}

const Member: React.FC<Props> = ({ member, disableActions }) => {
  //await inviteMember({ memberId, isUltimateBeneficialOwner: true }).unwrap()
  const toast = useToast()
  const modal = useDisclosure()

  const [linkUltimateBeneficialOwners, { isLoading: isLinkingUBOs }] =
    useLinkUltimateBeneficialOwnersMutation()

  const [createKycCase, { isLoading: isCreatingKycCase }] =
    useCreateKycCaseMutation()

  const [inviteMember, { isLoading: isInvitingMember }] =
    useInviteMemberMutation()

  const isLoading = isLinkingUBOs || isInvitingMember || isCreatingKycCase

  // const upgradeToUBO = async () => {
  //   let links: LinkUltimateBeneficialOwnersItemCommand[] = []

  //   links.push({
  //     memberId: member.id,
  //     isoCode: member.country?.isoCode ?? '',
  //   })

  //   await linkUltimateBeneficialOwners({ links }).unwrap()

  //   toast({
  //     position: 'bottom-right',
  //     render: () => (
  //       <Toast type="success">{`${member.name} has been successfully upgraded to Ultimate Beneficial Owner.`}</Toast>
  //     ),
  //   })
  // }

  const resendKycRequest = async () => {
    await createKycCase({ memberId: member.id }).unwrap()

    toast({
      position: 'bottom-right',
      render: () => (
        <Toast type="success">{`KYC request has been successfully resent.`}</Toast>
      ),
    })
  }

  const upgradeToMember = async () => {
    await inviteMember({
      memberId: member.id,
      isUltimateBeneficialOwner: true,
    }).unwrap()

    toast({
      position: 'bottom-right',
      render: () => (
        <Toast type="success">{`${member.name} has been upgraded to an account user`}</Toast>
      ),
    })
  }

  const resendMemberInvitation = async () => {
    await inviteMember({
      memberId: member.id,
      isUltimateBeneficialOwner: true,
    }).unwrap()

    toast({
      position: 'bottom-right',
      render: () => (
        <Toast type="success">{`Invitation has been sent to ${member.name} again`}</Toast>
      ),
    })
  }

  const isComplete =
    member.kycDetails?.kycStatus === 'Approved' &&
    member.isUser &&
    member.isUltimateBeneficialOwner

  return (
    <div className="flex items-center justify-between ">
      <div className="gap-3 flex items-center">
        <div className="w-10 h-10 rounded-full border-[1px] border-black/10 border-solid flex items-center justify-center color-text-default text-xs font-semi bg-brand-light-background dark:bg-brand-dark-background">
          {getInitials(member.name)}
        </div>
        <div className="flex flex-col">
          <p className="text-default-small">{member.name}</p>

          <p className="text-description">{member.email}</p>
        </div>
      </div>

      <div className="flex items-center gap-1">
        {isLoading && <Spinner size="sm" />}

        {member.isUser && (
          <InfoBox colorScheme="gray" className="text-xs">
            Account user
          </InfoBox>
        )}
        {member.isUltimateBeneficialOwner && (
          <InfoBox colorScheme="gray" className="text-xs">
            Ultimate Beneficial Owner
          </InfoBox>
        )}

        {member.kycDetails && (
          <KycStatus kycStatus={member.kycDetails.kycStatus} />
        )}

        {!isComplete && !disableActions && (
          <DropdownMenu
            renderList={
              <>
                {member.kycDetails?.kycStatus !== 'Completed' &&
                  member.kycDetails?.kycStatus !== 'Approved' && (
                    <DropdownCell
                      isActive={false}
                      onClick={() => resendKycRequest()}
                      title="Resend KYC Request"
                      icon={
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className="dark:text-white"
                        />
                      }
                    />
                  )}

                {member.isUser && !member.isUltimateBeneficialOwner && (
                  <DropdownCell
                    isActive={false}
                    title="Upgrade to Ultimate Beneficial Owner"
                    onClick={modal.onOpen}
                    icon={<FontAwesomeIcon icon={faCircleUp} />}
                  />
                )}
                {!member.isUser && member.isUltimateBeneficialOwner && (
                  <>
                    <DropdownCell
                      isActive={false}
                      onClick={() => upgradeToMember()}
                      title="Upgrade to Account user"
                      icon={
                        <FontAwesomeIcon
                          icon={faCircleUp}
                          className="h-4 w-4 dark:text-white"
                        />
                      }
                    />
                  </>
                )}
              </>
            }
          >
            <div className="w-8 h-8 border-[1px] border-black/10 border-solid flex items-center justify-center rounded-md">
              <FontAwesomeIcon icon={faEllipsisH} className="" />
            </div>
          </DropdownMenu>
        )}
      </div>

      <UpgradeToUboModal modal={modal} member={member} />
    </div>
  )
}

export default Member
