import { useDisclosure } from '@chakra-ui/react'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faBank } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import AddBankAccountModal from '../../../../components/AddBankAccountModal'
import PageWrapper from '../../../../components/Pagewrapper'
import Tooltip from '../../../../components/Tooltip'
import AsyncAlert from '../../../../containers/AsyncAlert'
import {
  useDeleteBankAccountMutation,
  useGetBankAccountsQuery,
} from '../../../../redux/services/coreApi'
import { RootState } from '../../../../redux/store'
import { BankAccountDto } from '../../../../types/coreApi-types'
import useAsyncAlert from '../../../../utils/useAsyncAlert'
import BankInformationModal from './BankInformationModal'

const BankAccountsScreen: React.FC = () => {
  const addBankAccountModal = useDisclosure()

  const [activeBankAccount, setActiveBankAccount] = useState<
    BankAccountDto | undefined
  >(undefined)

  const bankInformationModal = useDisclosure()

  const userInfo = useSelector((state: RootState) => state.session.user)

  const bankAccounts = useGetBankAccountsQuery(null)

  const [
    deleteBankAccount,
    { isError: deleteBankAccountError, reset: resetDeleteBankAccount },
  ] = useDeleteBankAccountMutation()

  const { openAlertAndAwaitConfirmation, alertProps } = useAsyncAlert<
    BankAccountDto,
    BankAccountDto
  >()

  const iconClass =
    'h-9 w-9 flex items-center justify-center cursor-pointer rounded-md text-brand-light-text-description dark:text-brand-dark-text-subtle-description group hover:bg-brand-light-background dark:hover:bg-brand-dark-background'

  const iconRed = 'hover:text-brand-light-red dark:hover:text-brand-dark-red'

  return (
    <PageWrapper
      title="Bank Accounts"
      description="Browse your bank accounts"
      fixedWidth
    >
      <BankInformationModal
        bankAccount={activeBankAccount}
        isOpen={bankInformationModal.isOpen}
        onClose={bankInformationModal.onClose}
      />

      <AddBankAccountModal
        isOpen={addBankAccountModal.isOpen}
        onClose={addBankAccountModal.onClose}
      />

      <div className="space-y-2">
        {bankAccounts.data &&
          bankAccounts.data.map((item, idx) => {
            return (
              <div
                key={idx}
                className="card"
                // className="card cursor-pointer hover:bg-brand-light-card-border"
                // onClick={() => {
                //   setActiveBankAccount(item)
                //   bankInformationModal.onOpen()
                // }}
              >
                <div className="flex justify-between">
                  <div>
                    <p className="font-semi">{item.name}</p>
                    <p className="text-description">{item.description}</p>
                  </div>
                  <div className="flex">
                    <Tooltip label="Open bank account details">
                      <div
                        onClick={() => {
                          setActiveBankAccount(item)
                          bankInformationModal.onOpen()
                        }}
                        className={`${iconClass} ${iconRed}`}
                      >
                        <FontAwesomeIcon icon={faBank} />
                      </div>
                    </Tooltip>
                    <Tooltip label="Delete bank account">
                      <div
                        onClick={async () => {
                          resetDeleteBankAccount()
                          await openAlertAndAwaitConfirmation(item, item)
                        }}
                        className={`${iconClass} ${iconRed}`}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      <div className="flex justify-center mt-10">
        <Tooltip
          label={
            userInfo.kycDetails?.kycStatus !== 'Approved'
              ? 'You need to have completed your KYC process to add a bank account'
              : undefined
          }
        >
          <button
            disabled={userInfo.kycDetails?.kycStatus !== 'Approved'}
            onClick={() => {
              addBankAccountModal.onOpen()
            }}
            className="button max-w-[150px]"
          >
            Add bank account
          </button>
        </Tooltip>
      </div>
      <AsyncAlert
        title={deleteBankAccountError ? 'Error' : 'Delete this bank account?'}
        bodyText={bankAccount =>
          deleteBankAccountError
            ? 'You cannot delete your bank account. This is probably due to existing transfers associated to this bank account. Please contact support if you must delete or change this bank account.'
            : `Are you sure you want to delete this bank account? Keep in mind you are not allowed to delete bank accounts that have associated transactions.`
        }
        submitButtonText={
          deleteBankAccountError ? 'Close' : 'Delete bank account'
        }
        onClick={async payload => {
          if (deleteBankAccountError) return

          if (payload) {
            const promise = deleteBankAccount(payload.id).unwrap()
            promise.catch(captureException)
            return promise
          }
        }}
        {...alertProps}
      />
    </PageWrapper>
  )
}

export default BankAccountsScreen
