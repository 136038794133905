import { Spinner, useToast } from '@chakra-ui/react'
import type React from 'react'
import { useHistory } from 'react-router-dom'
import AnimateHeight from '../../../../../../components/AnimateHeight'
import InfoBox from '../../../../../../components/InfoBox'
import KycStatusBox from '../../../../../../components/KycStatus'
import Toast from '../../../../../../components/Toast'
import {
  useCreateAmlForBusinessMutation,
  useCreateKycCaseMutation,
  useCreateQuestionnaireMutation,
  useUpdateKycStatusMutation,
} from '../../../../../../redux/services/coreApi'
import type { UserDto } from '../../../../../../types/coreApi-types'

interface KYCProps {
  client?: UserDto
}

const KYC: React.FC<KYCProps> = ({ client }) => {
  const history = useHistory()
  const isOrganisation = client?.role === 'Organization'

  const [updateKycStatus] = useUpdateKycStatusMutation()
  const toast = useToast()

  const [
    createAmlForBusiness,
    { data: amlForBusiness, isLoading: createAmlForBusinessLoading },
  ] = useCreateAmlForBusinessMutation()

  const [createKycCase, { isLoading: isCreatingKycCase }] =
    useCreateKycCaseMutation()

  const [createQuestionnaireCase, { isLoading: isCreatingQuestionnaireCase }] =
    useCreateQuestionnaireMutation()

  if (!client) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Spinner size="lg" />
      </div>
    )
  }

  return (
    <div>
      <h1 className="h1">KYC</h1>
      <div className="flex flex-col space-y-4">
        {isOrganisation && (
          <div className="card">
            <div>
              <div className="flex items-center justify-between">
                <h1 className="font-semi ">AML</h1>
                <button
                  className="button max-w-[100px]"
                  disabled={createAmlForBusinessLoading}
                  onClick={async () => {
                    if (!client) return
                    await createAmlForBusiness({
                      organizationId: client.id,
                    }).unwrap()
                  }}
                >
                  Check
                </button>
              </div>

              <AnimateHeight isVisible={!!amlForBusiness}>
                <InfoBox
                  className="w-min mt-2"
                  colorScheme={
                    amlForBusiness?.event === 'verification.accepted'
                      ? 'green'
                      : 'red'
                  }
                >
                  {amlForBusiness?.event}
                </InfoBox>
              </AnimateHeight>
            </div>
          </div>
        )}
        {client.kycDetails && (
          <>
            <div className="card">
              <div>
                <div className="flex items-center justify-between">
                  <h1 className="font-semi">
                    {client.role === 'Organization' ? 'KYB' : 'KYC'} Status
                  </h1>
                  <KycStatusBox kycStatus={client.kycDetails.kycStatus} />
                </div>
              </div>
            </div>
            {isOrganisation && (
              <div className="card">
                <div>
                  <div className="flex items-center justify-between">
                    <h1 className="font-semi">Questionnaire Status</h1>
                    <KycStatusBox
                      kycStatus={client.kycDetails.questionnaireStatus}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {client.kycDetails?.shuftiProKycReferenceId && (
          <>
            <div className="card">
              <div>
                <div className="flex flex-col justify-between gap-4">
                  <h1 className="font-semi">KYC Reference ID</h1>
                  <div>
                    <p
                      className="input"
                      style={{ lineHeight: 3, height: 'auto' }}
                    >
                      {client.kycDetails.shuftiProKycReferenceId}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {client.kycDetails?.shuftiProKybReferenceId && (
          <>
            <div className="card">
              <div>
                <div className="flex flex-col justify-between gap-4">
                  <h1 className="font-semi">KYB Reference ID</h1>
                  <div>
                    <p
                      className="input"
                      style={{ lineHeight: 3, height: 'auto' }}
                    >
                      {client.kycDetails.shuftiProKybReferenceId}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isOrganisation ? (
          <div className="card">
            <div>
              <div className="flex items-center justify-between">
                <h1 className="font-semi">Questionnaire Request</h1>
                <button
                  className="button max-w-[100px]"
                  disabled={isCreatingQuestionnaireCase}
                  onClick={async () => {
                    await createQuestionnaireCase({
                      userId: client.id,
                    }).unwrap()

                    toast({
                      position: 'bottom-right',
                      render: () => (
                        <Toast type="success">{`Questionnaire request has been successfully resent.`}</Toast>
                      ),
                    })
                  }}
                >
                  {isCreatingQuestionnaireCase ? (
                    <Spinner size="sm" />
                  ) : (
                    'Resend'
                  )}
                </button>
              </div>
              <div className="flex flex-col space-y-4 mt-4">
                {client.kycDetails?.shuftiProQuestionnaireUrl && (
                  <div>
                    <p>URL</p>
                    <p
                      className="input"
                      style={{ lineHeight: 3, height: 'auto' }}
                    >
                      {client.kycDetails.shuftiProQuestionnaireUrl}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <div>
              <div className="flex items-center justify-between">
                <h1 className="font-semi">KYC Request</h1>
                <button
                  className="button max-w-[100px]"
                  disabled={isCreatingKycCase}
                  onClick={async () => {
                    await createKycCase({ memberId: client.id }).unwrap()

                    toast({
                      position: 'bottom-right',
                      render: () => (
                        <Toast type="success">{`KYC request has been successfully resent.`}</Toast>
                      ),
                    })
                  }}
                >
                  {isCreatingKycCase ? <Spinner size="sm" /> : 'Resend'}
                </button>
              </div>
              <div className="flex flex-col space-y-4 mt-4">
                {client.kycDetails?.shuftiProKycUrl && (
                  <div>
                    <p>URL</p>
                    <p
                      className="input"
                      style={{ lineHeight: 3, height: 'auto' }}
                    >
                      {client.kycDetails.shuftiProKycUrl}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="card">
          <h1 className="font-semi mb-5">
            {client.role === 'Organization' ? 'KYB' : 'KYC'} Actions
          </h1>
          <div className="flex gap-2 ">
            <button
              className="button-danger"
              onClick={async () => {
                await updateKycStatus({
                  userId: client.id,
                  kycStatus: 'Declined',
                })
              }}
            >
              Deny
            </button>
            <button
              className="approve-button"
              onClick={async () => {
                await updateKycStatus({
                  userId: client.id,
                  kycStatus: 'Approved',
                })
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KYC
